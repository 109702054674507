import React, {useState} from "react";
import './App.css';
import Footer from "./components/Footer/Footer";
import {useLayoutEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { themeActions } from "./stores/reducers/theme";
import { filterActions } from "./stores/reducers/filter";
import { pagesActions } from "./stores/reducers/pages";
import { pageActions } from "./stores/reducers/page";
import Header from "./components/Header/Header";
import { Routes } from "react-router";
import {BrowserRouter, Route} from "react-router-dom";
import Page from "./components/Pages/Page";
import {fetchInit} from "./util/helpers"
import {updatedActions} from "./stores/reducers/page-updates";
import {Helmet} from "react-helmet";
import parse from 'html-react-parser';
import CookieBanner from "./components/CookieBanner/CookieBanner";


function App() {
  const dispatch = useDispatch();
  const pages = useSelector(state => state.pages.pages);
  const update_next = useSelector(state => state.updated.pages);
  const pathname = window.location.pathname;
  const isPreview = (window.location.toString()).includes('?preview_id=');
  const hasPage = (pathname in pages) && !(window.location.toString()).includes('?page_id=') && !isPreview;
  const [head, setHead] = useState('');
  const [fetched, setFetched] = useState(false);


  useLayoutEffect( () => {
    // is the page already stored in redux store
    // and is it not marked as modified
    if(hasPage && !(pathname in update_next)) {
      dispatch(pageActions.setCurrent(pages[pathname]));
      setHead(  typeof pages[pathname].seo_head !== "undefined" ? pages[pathname].seo_head : '');
    }
    else {
      // if page has been modified, re-fetch it and remove
      // it from list of modified pages in redux store
      if((pathname in update_next)){
        dispatch(updatedActions.removePage(pathname));
      }
      if(!fetched) {
        setFetched( true );

        fetchInit().then( data => {
          if( typeof data.options !== 'undefined' ) {
            dispatch( themeActions.fillOptions( data.options ) );
          }
          if( typeof data.filter !== 'undefined' ) {
            dispatch( filterActions.fillFilter( data.filter ) );
          }
          if( typeof data.page.seo_head !== 'undefined' ) {
            setHead(  typeof data.page.seo_head !== "undefined" ? data.page.seo_head : '');
          }
          if( typeof data.cookies !== 'undefined' ) {
            dispatch( themeActions.fillCookies( data.cookies ) );
          }

          if( typeof data.page !== 'undefined' ) {
            dispatch(pageActions.setCurrent(data.page));

            if(data.page && data.page.post_status === 'publish') {
              dispatch(pagesActions.addPage({
                'slug': pathname,
                'page': data.page
              }));
            }
          }
        } ).catch( error => console.log( error ) );
      }
    }
  }, [dispatch, hasPage, pages, pathname, update_next, fetched] );


  return (
    <React.Fragment>
      <Helmet>
        {parse( head )}
        <script type="application/javascript"
                src="https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js"></script>
        <script id="usercentrics-cmp" src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
                data-settings-id="q_0J_WdnfYK9F8" data-tcf-enabled async></script>
      </Helmet>
      <Header/>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Page/>}/>
        </Routes>
      </BrowserRouter>
      <Footer />
      <CookieBanner />
    </React.Fragment>
  );
}

export default App;
